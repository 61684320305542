import api from '@/services/api';

export default {
    data() {
        return {
            password: '',
            password2: '',
        };
    },

    methods: {
        submitChangeUsernameAndPassword() {
            if (this.fieldsAreEmpty()) {
                return;
            }
            if (this.passwordsDoNotMatch()) {
                return;
            }

            this.$Progress.start();
            const params = new URLSearchParams();

            params.append('password', this.password);
            api.post('/api/change-username-and-password', params)
                .then(() => {
                    this.$toast.success(this.$i18n.t('messages.success'));
                    this.$Progress.finish();
                }).catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
            this.resetInputs();
        },
        /**
         * Validate user inputs
         */
        fieldsAreEmpty() {
            if (this.trimString(this.password) === '' || this.trimString(this.password2) === '') {
                this.$toast.error(this.$i18n.t('messages.emptyFields'));
                return true;
            }
            return false;
        },

        /**
         * Validate user passwords
         */
        passwordsDoNotMatch() {
            if (this.trimString(this.password) !== this.trimString(this.password2)) {
                this.$toast.error(this.$i18n.t('messages.passwordsDoNotMatch'));
                return true;
            }
            return false;
        },

        /**
         * Reset input fileds values
         */
        resetInputs() {
            this.password = '';
            this.password2 = '';
        },

        /**
         * Some browsers do not support trim() function
         * @return {*}
         * @param string
         */
        trimString(string) {
            return string.replace(/^\s+|\s+$/gm, '');
        },
    },
};
