<template>
    <ul class="lang-switcher">
        <li>
            <button type="button"
                    :class="{'active': locale === 'de'}"
                    @click="changeLanguageLogin('de')"
            >DE</button>
        </li>
        <li>
            |
        </li>
        <li>
            <button type="button"
                    :class="{'active': locale === 'sr'}"
                    @click="changeLanguageLogin('sr')"
            >SRB</button>
        </li>
    </ul>
</template>

<script>
import LanguageSwitcher from '@/components/LanguageSwitcher';
export default LanguageSwitcher;
</script>
