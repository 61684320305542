import Vue from 'vue';
import VueRouter from 'vue-router';
import Tariffs from '@/components/Tariffs/Tariffs.vue';
import Tv from '@/components/Tv/Tv.vue';
import RechargeCredit from '@/components/RechargeCredit/RechargeCredit.vue';
import CustomerSupport from '@/components/CustomerSupport.vue';
import Bills from '@/components/Bills.vue';
import Login from '@/components/Authentication/Login.vue';
import ForgotPassword from '@/components/Authentication/ForgotPassword.vue';
import ResetPassword from '@/components/Authentication/ResetPassword.vue';
import Listing from '@/components/Listing/Listing.vue';
import Notification from '@/components/Notification/Notification.vue';
import Administration from '@/components/Administration/Administration.vue';
import Resources from '@/components/Resources/Resources.vue';
import PaymentThankYouPage from '@/components/PaymentThankYouPage/PaymentThankYouPage.vue';
import PaymentThankYouPagePayingBill from '@/components/PaymentThankYouPage/BillStatus/PaymentThankYouPagePayingBill.vue';
import Devices from '@/components/Devices/Devices.vue';
import NotFound from '@/components/404/NotFound.vue';
import MobilePaymentView from '@/components/PaymentMobileApp/MobilePaymentView.vue';
import PaymentThankYouPageMobile from '@/components/PaymentMobileApp/ThankYouPage/PaymentThankYouPageMobile.vue';
import TvTariffs from '@/components/Tv/TvTariffs/TvTariffs.vue';
import language from '@/middleware/language';
import auth from '@/middleware/auth';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Resources,
        name: 'resources',
        meta: {
            onlyIfAuthenticated: true,
            middleware: [language, auth]
        },
    },
    {
        path: '/tv',
        component: Tv,
        name: 'tv',
        meta: {
            onlyIfAuthenticated: true,
            middleware: [language, auth]
        },
    },
    {
        path: '/additional-packages',
        name: 'packages',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: 'about' */ '@/components/AdditionalPackages/AdditionalPackages.vue'),
        meta: {
            onlyIfAuthenticated: true,
            middleware: [language, auth]
        },
    },
    {
        path: '/recharge-credit',
        component: RechargeCredit,
        name: 'rechargeCredit',
        meta: {
            onlyIfAuthenticated: true,
            middleware: [language, auth]
        },
    },
    {
        path: '/customer-support',
        component: CustomerSupport,
        name: 'customerSupport',
        meta: {
            onlyIfAuthenticated: true,
            middleware: [language, auth]
        },
    },
    {
        path: '/Tariffs',
        component: Tariffs,
        name: 'tariffs',
        meta: {
            onlyIfAuthenticated: true,
            middleware: [language, auth]
        },
    },
    {
        path: '/tv-tariffs',
        component: TvTariffs,
        name: 'tvTariffs',
        meta: {
            onlyIfAuthenticated: true,
            middleware: [language, auth]
        },
    },
    {
        path: '/bills',
        component: Bills,
        name: 'bills',
        meta: {
            onlyIfAuthenticated: true,
            middleware: [language, auth]
        },
    },
    {
        path: '/listing',
        component: Listing,
        name: 'listing',
        meta: {
            middleware: [language, auth]
        },
    },
    {
        path: '/notification/:id',
        component: Notification,
        name: 'notification',
        meta: {
            middleware: [language, auth]
        },
    },
    {
        path: '/administration',
        component: Administration,
        name: 'administration',
        meta: {
            middleware: [language, auth]
        },
    },
    {
        path: '/transaction-completed',
        component: PaymentThankYouPage,
        name: 'paymentThankYouPage',
        meta: {
            middleware: [language, auth]
        },
    },
    {
        path: '/transaction-completed-mobile',
        component: PaymentThankYouPageMobile,
        name: 'paymentThankYouPageMobile',
        meta: {
            middleware: [language, auth]
        },
    },
    {
        path: '/transaction-completed-bill-paying',
        component: PaymentThankYouPagePayingBill,
        name: 'paymentThankYouPagePayingBill',
        meta: {
            middleware: [language, auth]
        },
    },
    {
        path: '/device',
        component: Devices,
        name: 'devices',
        meta: {
            middleware: [language, auth]
        },
    },
    {
        path: '/mobile-payment',
        component: MobilePaymentView,
        name: 'mobilePaymentView',
        meta: {
            middleware: [language, auth]
        },
    },
    {
        path: '/login',
        component: Login,
        name: 'login',
        meta: {
            middleware: [language, auth]
        }
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'forgotPassword',
        meta: {
            middleware: [language]
        }
    },
    {
        path: '/reset-password/:token',
        component: ResetPassword,
        name: 'resetPassword',
        meta: {
            middleware: [language]
        }
    },
    {
        path: '/404',
        component: NotFound,
        name: 'notFound',
        meta: {
            middleware: [language]
        },
    },
    {
        path: '*',
        redirect: '/404',
    },
];

const router = new VueRouter({
    base: process.env.BASE_URL,
    linkExactActiveClass: 'active-link',
    mode: 'history',
    routes,
});

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

/**
 * Middleware to check before each route
 *
 * @return  {void}
 */
router.beforeEach((to, from, next) => {
    if (process.env.VUE_APP_BRAND_NAME == 'viola') {
        document.title = 'Mein Viola';
    } else {
        document.title = 'Mein MTEL AT';
    }

    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];
        
        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

export default router;
