<template>
  <div id="login-wrapper">
    <div class="left-wrapper">
      <div class="logo-wrapper">
        <a href="#">
          <img
              v-if="brand === 'viola'"
              src="../../assets/logo-viola.png" alt="Viola logo"
          />
          <img v-else src="../../assets/logo.png" alt="MTEL AT logo" />
        </a>
      </div>
      <div id="wrapper-form">
        <h1>{{ $t('pages.resetPassword.title') }}</h1>
        <ResetPasswordForm />
        <LanguageSwitcher v-if="brand !== 'viola'"/>
      </div>

    </div>
    <div class="right-wrapper" :class="{'viola': brand === 'viola'}">
      <section class="landing-banner">
        <div class="wrapper">
          <h1 class="title">{{ $t('pages.login.rightTitle') }}</h1>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ResetPassword from './ResetPassword';
export default ResetPassword;
</script>
