import { mapActions, mapGetters } from 'vuex';
import api from '@/services/api';

export default {
    data() {
        return {
            phoneNumber: '',
            languageId: 2,
        };
    },

    computed: {
        ...mapGetters({
            user: 'user/user',
            contract: 'contract/contract',
            locale: 'locale',
            brand: 'brand',
        }),

        /**
         * Return title for chosen language (Serbisch / Srpski)
         * @return {string}
         */
        serbianLanguageTitle() {
            if (this.locale === 'de') {
                return 'Serbisch';
            }
            return 'Srpski';
        },

        /**
         * Return title for chosen language (Deutsch / Nemački)
         * @return {string}
         */
        germanyLanguageTitle() {
            if (this.locale === 'de') {
                return 'Deutsch';
            }
            return 'Nemački';
        },
    },

    methods: {
        ...mapActions('administration', ['changeLanguage']),

        /**
         * Change language for given number
         */
        submitChangeLanguage() {
            this.$Progress.start();
            const params = new URLSearchParams();

            params.append('language', this.languageId);
            params.append('phone', this.contract.clickedContract.phoneNumber);

            api.post('/api/change-mvno-language', params)
                .then(() => {
                    this.$toast.success(this.$i18n.t('messages.success'));
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },

        /**
         * Get language state on mounted
         */
        getLanguageState() {
            this.$Progress.start();
            const params = new URLSearchParams();
            params.append('phone', this.contract.clickedContract.phoneNumber);

            if (!this.contract.clickedContract.phoneNumber) {
                return;
            }

            api.post('/api/get-mvno-language', params)
                .then((response) => {
                    this.languageId = response.data.body.languageId;

                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },
    },

    /**
     * Listen for object changed for clicked contract
     */
    watch: {
        'contract.clickedContract.phoneNumber': {
            handler(phone) {
                this.phoneNumber = phone;
                this.getLanguageState();
            },
            deep: true,
        },
    },

    mounted() {
        this.getLanguageState();

        if (this.contract.clickedContract.phoneNumber) {
            this.phoneNumber = JSON.parse(JSON.stringify(this.contract.clickedContract.phoneNumber));
        }
    },
};
