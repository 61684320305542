import ResetPasswordForm from '@/components/Authentication/LoginForms/ResetPasswordForm.vue';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';

import { mapGetters } from 'vuex';

export default {
    components: {
        ResetPasswordForm,
        LanguageSwitcher
    },

    computed: {
        ...mapGetters({
            locale: 'locale',
            brand: 'brand',
        }),
    }
};
