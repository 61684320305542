import { mapGetters, mapActions } from 'vuex';
import router from "@/router";
import api from '@/services/api';

export default {
    data() {
        return {
            username: '',
        };
    },

    computed: {
        ...mapGetters({
            user: 'user/user',
        }),
    },

    methods: {
        submitForgotPassword() {
            if (this.fieldsAreEmpty()) {
                return;
            }

            this.$Progress.start();
            const params = new URLSearchParams();

            params.append('username', this.username);
            params.append('returnUrl', new URL('reset-password/', window.location.origin + window.location.pathname).href);
            api.post('/api/password-reset', params)
                .then(() => {
                    this.$toast.success(this.$i18n.t('messages.successReset'));
                    this.$Progress.finish();
                }).catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
            this.resetInputs();
        },
        /**
         * Validate user inputs
         */
        fieldsAreEmpty() {
            if (this.trimString(this.username) === '') {
                this.$toast.error(this.$i18n.t('messages.emptyFields'));
                return true;
            }
            return false;
        },

        /**
         * Reset input fileds values
         */
        resetInputs() {
            this.username = '';
        },

        /**
         * Some browsers do not support trim() function
         * @return {*}
         * @param string
         */
        trimString(string) {
            return string.replace(/^\s+|\s+$/gm, '');
        },


    },

    mounted() {
        this.$refs.usernameInput.focus();
    },
};
