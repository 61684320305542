export default {
    data() {
        return {
            storeLocationsUrl: process.env.VUE_APP_STORE_LOCATION_URL,
            faqUrl: process.env.VUE_APP_FAQ_URL,
            supportCalLUsUrl: process.env.VUE_APP_SUPPORT_CALL_US_URL,
            supportMailUsUrl: process.env.VUE_APP_SUPPORT_MAIL_US_URL,
            websiteUrl: process.env.VUE_APP_WEBSITE_URL,
            facebookUrl: process.env.VUE_APP_FACEBOOK_URL,
            youtubeUrl: process.env.VUE_APP_YOUTUBE_URL,
            instagramUrl: process.env.VUE_APP_INSTAGRAM_URL,
            brand: process.env.VUE_APP_BRAND_NAME,
        };
    },
};
