import ForgotPasswordForm from '@/components/Authentication/LoginForms/ForgotPasswordForm.vue';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';

import { mapGetters } from 'vuex';

export default {
    components: {
        ForgotPasswordForm,
        LanguageSwitcher
    },

    computed: {
        ...mapGetters({
            locale: 'locale',
            brand: 'brand',
        }),
    }
};
