import { mapActions, mapGetters } from 'vuex';
import router from '@/router';

export default {
    data() {
        return {
            username: '',
            password: '',
        };
    },

    computed: {
        ...mapGetters({
            user: 'user/user',
        }),
    },

    methods: {
        ...mapActions('user', ['loginViaPassword']),

        goToForgotPassword() {
            router.push('/forgot-password');
        }
    },

    mounted() {
        this.$refs.firstNameInput.focus();
    },
};
