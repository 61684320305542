<template>
    <div id="user-settings-tab" class="container tab-pane fade"><br>
        <div class="form-group">
            <label class="form-check-label" for="password">
                {{ $t('pages.administration.tab5.password') }}
            </label>
            <input class="form-control"
                   type="password"
                   id="password"
                   name="turnOnSmsNotification"
                   v-model="password"
            >
        </div>
        <div class="form-group">
            <label class="form-check-label" for="password">
                {{ $t('pages.administration.tab5.password2') }}
            </label>
            <input class="form-control"
                   type="password"
                   id="password2"
                   name="turnOnSmsNotification"
                   v-model="password2"
            >
        </div>
        <div class="form-group">
            <button class="red-button" @click="submitChangeUsernameAndPassword">
                {{ $t('pages.administration.tab5.button') }}
            </button>
        </div>
    </div>
</template>

<script>
import UserSettings from './UserSettings';
export default UserSettings;
</script>
