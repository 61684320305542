import { mapActions, mapGetters } from 'vuex';

export default {
    methods: {
        ...mapActions(['changeLanguageOnLoginPagesOnly']),
        changeLanguageLogin(language) {
            this.$i18n.locale = language;
            this.changeLanguageOnLoginPagesOnly(language);
        },
    },

    computed: {
        ...mapGetters({
            locale: 'locale',
        }),
    }
};
