import MsisdnForm from '@/components/Authentication/LoginForms/MsisdnForm.vue';
import UsernameForm from '@/components/Authentication/LoginForms/UsernameForm.vue';
import SmsForm from '@/components/Authentication/LoginForms/SmsForm.vue';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            msisdnLoginMethodActive: true,
            usernameLoginMethodActive: false,
        };
    },

    components: {
        MsisdnForm,
        UsernameForm,
        SmsForm,
        LanguageSwitcher
    },

    methods: {
        /**
         * Toggles the msisdnLoginMethodActive property
         *
         * @return  {void}
         */
        toggleActiveParameter(type) {
            this.user.error = '';

            if (type === 'msisdn') {
                this.msisdnLoginMethodActive = true;
                this.usernameLoginMethodActive = false;
                return;
            }
            this.msisdnLoginMethodActive = false;
            this.usernameLoginMethodActive = true;
            // After sms is sent and user choose another method rather then sms login,
            // then set sms to false and show him another method that is chosen
            this.user.isSmsCodeSent = false;
        },
    },

    computed: {
        ...mapGetters({
            user: 'user/user',
            locale: 'locale',
            brand: 'brand'
        }),
    }
};
