<template>
  <form id="msisdn-form">
    <label class="landing-label">
      <span class="label-title">{{ $t('pages.administration.tab5.password') }}</span>
      <input type="password"
        v-model.trim="password"
        ref="passwordInput"
      >
      <small v-if="user.validation_errors && user.validation_errors.password">
        {{ user.validation_errors.password[0] }}
      </small>
    </label>

    <label class="landing-label">
      <span class="label-title">{{ $t('pages.administration.tab5.password2') }}</span>
      <input type="password"
        v-model.trim="password2"
        ref="password2Input"
      >
      <small v-if="user.validation_errors && user.validation_errors.password2">
        {{ user.validation_errors.password2[0] }}
      </small>
    </label>

    <small v-if="user.error && !(user.validation_errors && (user.validation_errors.password && user.validation_errors.password2))">
        {{ user.error }}
    </small>

    <fieldset class="buttons">
      <button type="button"
              class="main-btn submit-btn phone-number"
              @click="submitChangedPassword"
      >
        <span class="spinner-label">{{ $t('pages.resetPassword.submit') }}</span>
      </button>
    </fieldset>
  </form>
</template>

<script>
import ResetPasswordForm from './ResetPasswordForm';
export default ResetPasswordForm;
</script>
